import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

import { ModalPopUp } from "../Modal/ModalPopUp"
import GetPropertyDetailsForm from "../Forms/GetPropertyDetailsForm"

export const GetDetails = ({ size = "lg" }) => {
  return (
    <Container>
      <Row>
        <Col className="text-center my-3">
          <ModalPopUp
            trigger={
              <Button variant="secondary" size={size}>
                Get Details
              </Button>
            }
          >
            <GetPropertyDetailsForm />
          </ModalPopUp>
        </Col>
      </Row>
    </Container>
  )
}
