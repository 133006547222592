/* eslint jsx-a11y/control-has-associated-label: "off", curly: "error" */

import React, { Fragment } from "react"

import CommonFields from "./Inputs/CommonFields"
import CurrentPage from "./Inputs/CurrentPage"
import NetlifyForm from "./Template/NetlifyForm"

const PropertyEnquiryForm = () => (
  <Fragment>
    <h2 className="h4 text-center">Enquire about this property today</h2>

    <NetlifyForm name="property-enquiry" btnText="Request Information">
      <CommonFields />

      <CurrentPage />
    </NetlifyForm>

    <div className="text-center small">
      <p>
        By completing this form, you give us permission to send your contact
        information to the property developer or their appointed agent.
      </p>
      <p>
        They will share the property brochure and relevant information to help
        you make an informed decision. You hereby agree that your details will
        be added to a mailing list to receive emails of similar opportunities.
      </p>
    </div>
  </Fragment>
)

export default PropertyEnquiryForm
