import React from "react"
import styled from "styled-components"

const StyledPropertySpecification = styled.div`
  padding: 4rem;
`

export const PropertySpecification = ({ propertySpecification }) => {
  return (
    <StyledPropertySpecification>
      <h2>Property Specification</h2>
      {propertySpecification && (
        <div
          dangerouslySetInnerHTML={{
            __html: propertySpecification.childMarkdownRemark.html,
          }}
        />
      )}
    </StyledPropertySpecification>
  )
}
