import React from "react"
import { Icon } from "../IconFactory/Icons"

import styled from "styled-components"

const StyledPropertyTheNumbers = styled.div`
  padding: 4rem 1rem;

  svg {
    width: 25px;
    height: 25px;
    margin-bottom: 1rem;
  }
`

export const PropertyTheNumbers = ({ property }) => {
  return (
    <StyledPropertyTheNumbers>
      <h3 className="mb-4">The Numbers</h3>

      <p>
        Ground Rent:
        <strong className="ml-1">{property.groundRent}</strong>
      </p>
      <Icon name={property.sector && property.sector.key} />
      <p>
        Number of Units:
        <strong className="ml-1">{property.numberOfUnits}</strong>
      </p>
      <Icon name={property.sector && property.sector.key} />
      <p>
        Service Charge:
        <strong className="ml-1">{property.serviceCharge}</strong>
      </p>
      <Icon name={property.sector && property.sector.key} />
      <p>
        Prices:
        <strong className="ml-1">{property.prices}</strong>
      </p>
      <Icon name={property.sector && property.sector.key} />
      <p>
        Leasehold:
        <strong className="ml-1">{property.lease}</strong>
      </p>
    </StyledPropertyTheNumbers>
  )
}
