import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import styled from "styled-components"
import { mediaBreakpoint } from "../../utils/breakpoints"

export const StyledSVGHalf = styled.svg`
  width: 100%;
  vertical-align: bottom;

  &.mirror-y {
    transform: scaleX(-1);
    vertical-align: bottom;
  }
  &.mirror-x {
    transform: scaleY(-1);
    vertical-align: top;
  }
  &.mirror-x-y {
    vertical-align: top;
    transform: scaleX(-1) scaleY(-1);
  }

  path {
    fill: ${props => props.theme[props.variant]};
  }

  @media ${mediaBreakpoint.down.lg} {
    path {
      fill: ${props => props.theme.primary};
    }
  }
`

const BackgroundWrapper = styled.div`
  text-align: center;

  background: linear-gradient(
    90deg,
    ${props => props.theme[props.variantStart]} 50%,
    ${props => props.theme[props.variantEnd]} 50%
  );

  @media ${mediaBreakpoint.down.lg} {
    background: ${props => props.theme.primary};
    color: #fff;
  }
`

export const SectionHalves = ({ left, right, noTopCurve }) => {
  return (
    <div>
      {!noTopCurve && (
        <div>
          <Row noGutters>
            <Col>
              <StyledSVGHalf variant="primary" viewBox="0 0 971.4 58.3">
                <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
              </StyledSVGHalf>
            </Col>
            <Col>
              <StyledSVGHalf
                variant="light"
                className="mirror-y"
                viewBox="0 0 971.4 58.3"
              >
                <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
              </StyledSVGHalf>
            </Col>
          </Row>
        </div>
      )}
      <BackgroundWrapper variantStart="primary" variantEnd="light">
        <Container>
          <Row>
            <Col lg={6}>{left}</Col>
            <Col lg={6}>{right}</Col>
          </Row>
        </Container>
      </BackgroundWrapper>
    </div>
  )
}
