import React from "react"
import styled from "styled-components"

const StyledPropertySummary = styled.div`
  padding: 4rem;
  color: #fff;
`

export const PropertySummary = ({ summary }) => {
  return (
    <StyledPropertySummary>
      <h2>Property Summary</h2>
      {summary && (
        <div
          dangerouslySetInnerHTML={{
            __html: summary.childMarkdownRemark.html,
          }}
        />
      )}
    </StyledPropertySummary>
  )
}
