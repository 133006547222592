import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { CurveCarveTop, CurveBulgeBottom } from "../../components/Curves/Curve"
import { graphql } from "gatsby"
import { HeroCTAForm } from "../../components/Hero/HeroCTAForm"
import {
  SectionHalves,
  StyledSVGHalf,
} from "../../components/SqueezePage/SectionHalves"
import { FaCircle } from "react-icons/fa"

import { PropertySummary } from "../../components/SqueezePage/PropertySummary"
import { PropertySpecification } from "../../components/SqueezePage/PropertySpecification"
import { LogoSection } from "../../components/SqueezePage/LogoSection"
import { PropertyTheNumbers } from "../../components/SqueezePage/PropertyTheNumbers"
import { PropertyPhotos } from "../../components/Property/PropertyPhotos"

import { GetDetails } from "../../components/Misc/GetDetails"
import PropertyEnquiryForm from "../../components/Forms/PropertyEnquiryForm"

import { SqueezePageContext } from "./SqueezePageContext"

const PropertySqueezePage = ({
  location,
  pageContext,
  data: { contentfulSqueezePage: squeezePage },
}) => {
  if (!squeezePage) {
    return (
      <Layout>
        <Container className="text-center">
          <Row>
            <Col>
              <p className="lead">
                Error, can't find Squeeze page: {pageContext.contentful_id}
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }

  const { property } = squeezePage

  if (!property) {
    return (
      <Layout>
        <Container className="text-center">
          <Row>
            <Col>
              <p className="lead">
                No Property Attached to Squeeze page:{" "}
                {pageContext.contentful_id}
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }

  return (
    <SqueezePageContext location={location}>
      <Layout>
        <SEO title={property.name} />
        <div id="top" />

        <HeroCTAForm
          fluid={property.heroPhoto.fluid}
          variant={property.sector && property.sector.key}
          curveTop={<CurveBulgeBottom variant="white" />}
          curveBottom={<CurveCarveTop variant="white" />}
          form={<PropertyEnquiryForm />}
        >
          <div className="sector">
            {property.sector && property.sector.subtitle}
          </div>
          <h1 className="name">{property.name}</h1>
          <div className="price bg-secondary">
            From: {`£${property.startingPrice.toLocaleString("en-GB")}`}
          </div>

          <div className="features">
            {property.highlights &&
              property.highlights.map((highlight, i) => (
                <div key={highlight} className="feature-item">
                  <FaCircle className="text-secondary" />
                  <div>{highlight}</div>
                </div>
              ))}
          </div>
        </HeroCTAForm>

        <div id="key-figures">
          <Container className="py-4 text-center">
            <Row>
              <Col>
                <h2 className="mb-5">Key Figures</h2>
              </Col>
            </Row>
            <Row>
              <Col lg={4} className="my-5">
                <h3>
                  <div className="display-3 mb-4">{`£${property.startingPrice.toLocaleString(
                    "en-GB"
                  )}`}</div>
                  Starting Price
                </h3>
              </Col>
              <Col lg={4} className="my-5">
                <h3>
                  <div className="display-3 mb-4">{property.yield}%</div>
                  Net Yield
                </h3>
              </Col>
              <Col lg={4} className="my-5">
                <h3>
                  <div className="display-3 mb-4">
                    {property.completionDateQuarter}
                  </div>
                  Completion Date
                </h3>
              </Col>
            </Row>
          </Container>
        </div>

        <div id="summary-specification" />
        <SectionHalves
          left={<PropertySummary summary={property.summary} />}
          right={
            <PropertySpecification
              propertySpecification={property.propertySpecification}
            />
          }
        />

        <HeroCTAForm
          fluid={property.photosBig[0].fluid}
          variant={property.sector && property.sector.key}
          curveTop={
            <div>
              <Row noGutters>
                <Col>
                  <StyledSVGHalf
                    variant="primary"
                    className="mirror-x"
                    viewBox="0 0 971.4 58.3"
                  >
                    <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
                  </StyledSVGHalf>
                </Col>
                <Col>
                  <StyledSVGHalf
                    variant="light"
                    className="mirror-x-y"
                    viewBox="0 0 971.4 58.3"
                  >
                    <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
                  </StyledSVGHalf>
                </Col>
              </Row>
            </div>
          }
        />

        <div id="photos" />
        <PropertyPhotos
          photos={property.photos}
          curveBottom={
            <div>
              <Row noGutters>
                <Col>
                  <StyledSVGHalf
                    variant="primary"
                    className=""
                    viewBox="0 0 971.4 58.3"
                  >
                    <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
                  </StyledSVGHalf>
                </Col>
                <Col>
                  <StyledSVGHalf
                    variant="light"
                    className="mirror-y"
                    viewBox="0 0 971.4 58.3"
                  >
                    <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
                  </StyledSVGHalf>
                </Col>
              </Row>
            </div>
          }
        />

        <div id="numbers" />
        <SectionHalves
          noTopCurve
          left={<LogoSection />}
          right={<PropertyTheNumbers property={property} />}
        />

        <div>
          <Row noGutters>
            <Col>
              <StyledSVGHalf
                variant="primary"
                className="mirror-x"
                viewBox="0 0 971.4 58.3"
              >
                <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
              </StyledSVGHalf>
            </Col>
            <Col>
              <StyledSVGHalf
                variant="light"
                className="mirror-x-y"
                viewBox="0 0 971.4 58.3"
              >
                <path d="M971.4,46.5v11.8H0V0C0,0,419.5,44.7,971.4,46.5z" />
              </StyledSVGHalf>
            </Col>
          </Row>
        </div>

        <div id="contact" />
        <Container>
          <Row>
            <Col>
              <GetDetails />
            </Col>
          </Row>
        </Container>
      </Layout>
    </SqueezePageContext>
  )
}

export const query = graphql`
  query PropertySqueezePageQuery($contentful_id: String) {
    contentfulSqueezePage(contentful_id: { eq: $contentful_id }) {
      title
      seoTitle
      seoDescription {
        seoDescription
      }
      permalink
      property {
        ...PropertyFragment
        photosBig: photos {
          id
          title
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default PropertySqueezePage
