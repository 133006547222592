import React from "react"
import { Icon } from "../IconFactory/Icons"
import styled from "styled-components"

const StyledLogoSection = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;

  svg {
    width: 50px;
  }
`

export const LogoSection = () => {
  return (
    <StyledLogoSection>
      <div>
        <h3>One Touch</h3>
        <Icon name="logo" />
      </div>
    </StyledLogoSection>
  )
}
